import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

/*
// Large cards (2) image widths at all breakpoints:
// layout:     | ----------------- 1 col ----------------- | -------------------------- 2 col --------------------------- |
// Container:  | --- xxs --- | ---- xs ---- | ---- sm ---- | ---- md ---- | ---- lg ----- | ---- xl ----- | ---- xxl ----->
// Breakpoint: 0            375            600            768            992             1200            1920             ~
// Img width:  |  290-344px  |   345-370px  |     546px    |    330px     |     450px     |     540px     |     540px     |
*/

const LargeCards = (props) => {
  const imgData = useStaticQuery(graphql`
    {
      largeCard1ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-greenlight-082224.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-credit-rewards-082224.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const defaultCardData = [
    {
      // Default (Card 1)
      cardNumber: 1,
      imgData: imgData.largeCard1ImageVar.childImageSharp.gatsbyImageData,
      altText: "Mother with phone and debit card teaching child about finances.",
      heading: "Teach Your Kids Smart Spending with Greenlight",
      text: "Be there every step with a debit card with parental controls and much more, courtesy of WaFd.",
      linkUrl: "/personal-banking/greenlight-kids-debit-card",
      btnText: "Get Greenlight"
    },
    {
      // Default (Card 2)
      cardNumber: 2,
      imgData: imgData.largeCard2ImageVar.childImageSharp.gatsbyImageData,
      altText: "Happy couple in restaurant with laptop and credit card.",
      heading: "Get a Credit Card with Cash Back",
      text: "Maximize your savings on everyday purchases with a WaFd credit card.",
      linkUrl: "/personal-banking/credit-cards",
      btnText: "Check it Out"
    }
  ];

  // Use Props cardData if found, else use the default (used here due to imageVariable being used on the cards).
  const cardData = props.cardData || defaultCardData;
  const btnText = props.lang === "es" ? "Conoce más" : "Learn More";

  /*
   * NOTE: please do NOT change the margin-bottom classes on these cards, it should be "mb-3 mb-lg-4".
   * Do NOT override the spacing using padding or margin classes on the props.containerClass.
   */
  return (
    <div className={`container ${props.containerClass}`}>
      <div id="homepage-large-cards" className="row large-cards">
        {cardData.map((card, cardIndex) => {
          return (
            <div className="col-md mb-3 mb-lg-4" key={cardIndex}>
              <div
                id={`homepage-large-card-${card.cardNumber}`}
                className="card h-100 position-relative border-radius-12 border-0"
              >
                <GatsbyImage
                  className="border-radius-12 border-radius-bottom-0"
                  image={card.imgData}
                  alt={card.altText}
                />
                <div className="card-body pb-0">
                  <h2
                    className="text-success font-weight-semi-bold"
                    dangerouslySetInnerHTML={{ __html: card.heading }}
                  />
                  <h4 dangerouslySetInnerHTML={{ __html: card.text }} />
                </div>
                <div className="card-footer bg-white border-0 border-radius-bottom-12">
                  <div className="row">
                    <div className="col-sm-8 col-md-12 col-lg-8">
                      <Link
                        id={`homepage-large-card-${card.cardNumber}-btn`}
                        className="btn btn-primary w-100 stretched-link"
                        to={card.linkUrl}
                      >
                        {card.btnText || btnText}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LargeCards;

LargeCards.defaultProps = {
  containerClass: "bg-info",
  cardData: null,
  lang: "en"
};
